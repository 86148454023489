import { render } from "./FilterForm.vue?vue&type=template&id=0d059d26"
import script from "./FilterForm.ts?vue&type=script&lang=ts"
export * from "./FilterForm.ts?vue&type=script&lang=ts"

import "./filter-form.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0d059d26"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0d059d26', script)) {
    api.reload('0d059d26', script)
  }
  
  module.hot.accept("./FilterForm.vue?vue&type=template&id=0d059d26", () => {
    api.rerender('0d059d26', render)
  })

}

script.__file = "src/views/Catalog/components/FilterForm/FilterForm.vue"

export default script